
/* HEADER COMPONENT */
.header-container {
  top: 5px;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  height: 5vh;
  font-size: 14px;
}

.header-container-left {
    margin-left: 1vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 30%;
}

.header-container-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 30%;
}

.header-item {
    margin-right: 18px;
    color: var(--item-color);
    text-decoration: none;
    transition: text-decoration 0.3s;
}

.header-item:hover {
    text-decoration: underline;
}

.header-button {
    cursor: pointer;
    padding-right: 4px;
}

.join-now-button {
    background-color: var(--item-color);
    color: var(--background-color);
    border: none;
    border-radius: 5px;
    padding: 6px 8px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
}

/* SEARCH COMPONENT */
.search-container-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80vw;
}

.search-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: inherit;
    height: 10vh;
    margin-bottom: 20px;
}

.search-item-zero {
    margin-right: 30px;
    width: 150px;
}

.MuiFormHelperText-root {
    position: absolute;
    bottom: -20px;
}

.search-item {
    margin-right: 22px;
    width: 100%;
}

.search-item-two {
    width: 150px;
}

.search-item-three {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100vw;
    z-index: 5;
}

.search-mobile-lower-view {
    display: inline-flex;
    flex: 1;
    width: inherit;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;
} 

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined {
  color: var(--item-color);
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .search-container {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      width: 90vw;
  }

  .search-item-zero,
  .search-item,
  .search-item-two {
      margin-right: 0;
      width: auto; /* Adjust this as needed */
  }

  .search-item-zero {
      margin-bottom: 20px; /* Adds space between the search field and the items below */
  }
}


/* FOOTER COMPONENT */
.footer-container {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 5vh;
    font-size: 14px;
    z-index: 100;
}

.footer-container-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 30%;
    margin-left: 5vw;
}

.footer-container-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: calc(5vw - 18px);
    width: 30%;
}

.footer-item {
    margin-right: 18px;
    color: var(--item-color);
    text-decoration: none;
    transition: text-decoration 0.3s;
}

.footer-item:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .footer-container {
        display: flex;

        flex-direction: row;
        position: fixed;
        bottom: 0;
        align-items: center;
        height: auto;
        width: 90vw;
        justify-content: space-between;
        width: 100%;
        height: 5vh;
        background-color: rgba(0, 0, 0, 0.12);
        font-size: 14px;
    }
    .footer-container-left {}
    .footer-container-right {}
    .footer-item-label {
        display: none;
    }
}


/* RESULTS COMPONENT */
.results-root-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 5vh 0;
    overflow: scroll;
    /* hide scroll bar */
    scrollbar-width: none;
    width: 80%;
}

.result-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: #fff; /* Change as needed */
    border-radius: 15px;
    overflow: hidden; /* Ensures the image and content fit within the rounded corners */
    box-shadow: 0 2px 4px rgba(0,0,0,0.4); /* Optional shadow for depth */
  }
  
  .result-image {
    flex: 0 0 50%; /* Adjust the width as needed */
    height: 100px; /* Fixed height, adjust as needed */
    object-fit: cover; /* Ensures the image covers the area */
  }
  
  .result-info {
    padding: 10px;
    flex: 1;
    color: var(--item-color);
  }
  
  .result-info h3 {
    margin-top: 0;
  }
  
  .result-info p {
    margin: 5px 0;
  }
  
  .result-info a {
    color: #1a0dab; /* Google link color */
    text-decoration: none;
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .result-container {
      flex-direction: column;
    }
  
    .result-image {
      width: 100%; /* Full width on smaller screens */
      height: auto; /* Adjust height automatically */
    }
  
    .result-info {
      text-align: center; /* Center info text on smaller screens */
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .result-enter-active {
    opacity: 0;
    animation: fadeInUp 0.5s ease forwards;
  }

  .dialog-bottom-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-bottom: 10px;
  }

  /* PRODUCT CARD COMPONENT */
  .product-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 100%;
    padding: 20px;
    margin-bottom: 10vh;
    cursor: pointer;
  }

  .product-card-title {
    font-weight: 600;
    margin: 10px;
    width: 60%;
  }

  .product-card-content {
    font-size: 16px;
    color: var(--light-text-color);
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .product-card-features-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    /* box-shadow: 0 2px 4px rgba(0,0,0,0.3);     */
  }

  .product-card-features-title {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row;
    width: 30%;
  }

  .product-card-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start; 
  }

  .product-card-price {
    font-size: 18px;
    font-weight: 600;
    color: var(--item-color);
  }
  
  .product-image-container {
    width: 50%;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }

  .product-image {
    width: 70%;
    object-fit: cover;
    margin-bottom: 10vh;
    animation: fadeInLeft 1.5s ease;
  }

  /* animate image pan in from left */
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-100px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }


  .product-card-disclaimer {
    font-size: 12px;
    color: var(--light-text-color);
    margin-top: 10px;
  }
  
  @media (max-width: 768px) {
    .product-card-container {
      width: 90%;
      padding: 10px;
    }
  
    .product-card-title {
      font-size: 1.5rem;
      width: 100%;
    }
  
    .product-card-content {
      font-size: 12px;
      width: 100%;
    }
  
    .product-card-features-title {
      width: 100%;
    }
  
    .product-card-features-container {
      flex-direction: column;
    }

    .product-image-container {
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }