.App {
  text-align: center;
}

.App-header {
  background-color: #282c34; /* User.darkModeEnabled */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media (max-width: 768px) {
  .App {
    overflow: hidden;
  }
  .App-header {
    min-height: 100vh;
    overflow: hidden;
  }
}



.App-link {
  color: #61dafb;
}
