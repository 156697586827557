/* MAIN COMPONENT */
.home-container  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 27vh;
    height: 73vh;
    width: 100%;
}

.home-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.home-title {
    margin: 20px 0;
    color: var(--item-color);
}

.home-title-text-container {
    display: flex;
    flex-direction: row;
}

.header-logo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 30px;
    object-fit: cover;
  }

@media (max-width: 768px) {

    .home-container {
        padding-top: 18vh;
        height: 82vh;
    }

    .home-title {
        font-size: 1.5rem;
    }

    .home-title-container {
        flex-direction: row;
        align-items: flex-start;
        align-items: center;
        justify-content: center;
    }

    .header-logo { 
        width: 48px;
        height: 48px;
    }
    
}

/* PAYMENT COMPONENT */
.product-container {
    display: flex; 
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-top: 10vh;
}

@media (max-width: 768px) {
    .product-container {
        flex-direction: column;
        margin-top: 10vh;
    }
}

/* POLICY COMPONENT */

.policy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

/* EMAIL COMPONENT */

.email-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 5vh;
}

.email-button {
    height: 50px;
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {

    .email-button-container {
        flex-direction: column;
    }

    .email-button {
        font-size: 1rem;
    }
}

/* VERIFICATION CONTROLLER */
.verification-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.verification-title {
    margin: 20px 0;
    color: var(--item-color);
}